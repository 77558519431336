module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-decap-cms@4.0.4_webpack@5.97.1/node_modules/gatsby-plugin-decap-cms/gatsby-browser.js'),
      options: {"plugins":[],"manualInit":true,"modulePath":"/actions-runner/_work/digital-agency-services/digital-agency-services/frontend/apps/web/src/cms/cms.js","publicPath":"7fdb7e36"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_graphql@16.9.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/favicon.ico","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9d776b3ffe2bd307be08fad8c834892a"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-decap-cms@4.0.4_webpack@5.97.1/node_modules/gatsby-plugin-decap-cms/gatsby-browser.js'),
      options: {"plugins":[],"manualInit":true,"modulePath":"/actions-runner/_work/digital-agency-services/digital-agency-services/frontend/apps/web/src/cms/cms.js","publicPath":"en/7fdb7e36"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-decap-cms@4.0.4_webpack@5.97.1/node_modules/gatsby-plugin-decap-cms/gatsby-browser.js'),
      options: {"plugins":[],"manualInit":true,"modulePath":"/actions-runner/_work/digital-agency-services/digital-agency-services/frontend/apps/web/src/cms/cms.js","publicPath":"ko/7fdb7e36"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-decap-cms@4.0.4_webpack@5.97.1/node_modules/gatsby-plugin-decap-cms/gatsby-browser.js'),
      options: {"plugins":[],"manualInit":true,"modulePath":"/actions-runner/_work/digital-agency-services/digital-agency-services/frontend/apps/web/src/cms/cms.js","publicPath":"zh-cmn-hans/7fdb7e36"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-decap-cms@4.0.4_webpack@5.97.1/node_modules/gatsby-plugin-decap-cms/gatsby-browser.js'),
      options: {"plugins":[],"manualInit":true,"modulePath":"/actions-runner/_work/digital-agency-services/digital-agency-services/frontend/apps/web/src/cms/cms.js","publicPath":"zh-cmn-hant/7fdb7e36"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_react-dom@18.3.1_react@18.3.1_typescript@5.4.2/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
